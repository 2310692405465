import { DROP_HERO_TYPES } from '@/enum'
import DropMonstersHero from './DropMonstersHero'
import DropMonstersLandingHero from './DropMonstersLandingHero'
import DropStandardHero from './DropStandardHero'
import DropStandardLandingHero from './DropStandardLandingHero'
import {
  DropProvider,
  useDropContext
} from '@/features/drop/DropContextProvider'

interface IDropHeroFactory {
  drop: any
  type?: string
}

const DropHeroFactory = ({
  drop,
  type = DROP_HERO_TYPES.STANDARD
}: IDropHeroFactory) => {
  return (
    <DropProvider drop={drop}>
      <HeroComponent type={type} />
    </DropProvider>
  )
}

export default DropHeroFactory

type Props = {
  type?: string
}
const HeroComponent = ({ type = DROP_HERO_TYPES.STANDARD }: Props) => {
  const { drop } = useDropContext()
  if (type === DROP_HERO_TYPES.STANDARD) {
    switch (drop.dropHeroComponent) {
      case 'DropMonstersHero':
        return <DropMonstersHero />
      default:
        return <DropStandardHero />
    }
  } else {
    switch (drop.dropLandingHeroComponent) {
      case 'DropMonstersLandingHero':
        return <DropMonstersLandingHero />
      default:
        return <DropStandardLandingHero />
    }
  }
}
