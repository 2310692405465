import React from 'react'
import { useDropTimerContext } from '../DropTimer'
import {
  ButtonDisabledTheme,
  ButtonLink,
  ButtonSize,
  ButtonTheme
} from '@/components/Button'
import { Drop } from '@/types/drop'
import { classNames } from '@/util/tailwindHelpers'
import { isRewardsDrop } from '@/util/dropHelpers'
import { Icon } from '@/components'

export const DropQueueButton = ({
  className = '',
  drop,
  theme = 'secondary',
  disabledTheme = 'informational',
  size = 'lg'
}: {
  className?: string
  drop: Drop
  theme?: ButtonTheme
  disabledTheme?: ButtonDisabledTheme
  size?: ButtonSize
}) => {
  const {
    remainingUntilQueueOpen: { seconds, display }
  } = useDropTimerContext()
  const isDisabled = seconds === null || seconds > 0
  const buttonClasses = 'flex items-center mr-[4px]'
  const buttonHref = isRewardsDrop(drop)
    ? `${drop.url}/?goto=openables-section`
    : `/reserve-drop/?drop_id=${drop.id}`
  const buttonPrefix = isRewardsDrop(drop) ? (
    <span className={buttonClasses}>
      <Icon name="crown" className="mr-1" />
      {seconds > 0 ? 'Get Packs In' : 'Get Packs'}
    </span>
  ) : (
    <span className={buttonClasses}>Join Queue</span>
  )
  return (
    <ButtonLink
      href={buttonHref}
      disabledTheme={disabledTheme}
      theme={theme}
      size={size}
      disabled={isDisabled}
      className={classNames('tabular-nums', className)}
    >
      {buttonPrefix}
      {isDisabled && display}
    </ButtonLink>
  )
}
