import { DateFormatter, isOnOrBeforeNow } from '@/util/time'
import classNames from 'classnames'
import DropAvailDate from './DropAvailDate'
import { formattedPacificDate } from '@/util/time/pt'
import { useDropContext } from '@/features/drop/DropContextProvider'
import { dropAvailabilityString } from '../DropAvailability'
import { isRewardsDrop } from '@/util/dropHelpers'
import { redeemableRarityString } from '@/util/rarityHelpers'

type Props = {
  className?: string
  isDetailPage?: boolean
}

const baseClassName = 'body md:body-lg mb-3 max-w-[832px] md:px-4'

const DropHeroDescription = ({ className, isDetailPage = false }: Props) => {
  const {
    drop,
    time_launch,
    isPostSale,
    isPostSnapshot,
    isPostRedemption,
    redeem_end_date,
    redeem_start_date
  } = useDropContext()

  const rewardDrop = isRewardsDrop(drop)
  const availableDate = dropAvailabilityString(time_launch)

  let description = drop.mainCopy + ` ${availableDate} `

  const getBladeDescription = () => {
    if (isDetailPage) {
      if (rewardDrop) {
        return getRewardsDescription()
      }

      if (isPostSale)
        description = `Shop the Droppp Marketplace for digital collectibles. Collect a ${redeemableRarityString(
          drop
        )} before ${formattedPacificDate(
          redeem_start_date
        )} to claim your exclusive Pop! vinyl figures.`

      if (isPostSnapshot)
        description = `Redemption Tokens are now available! Collect yours to redeem for the physical counterpart. Redeem by ${formattedPacificDate(
          redeem_end_date,
          DateFormatter.LongHoursMinutes,
          true
        )} before they expire!`

      if (isPostRedemption)
        description =
          'The redemption period for this collection has already ended, and the redemption tokens are no longer redeemable for Pop! vinyl figures.'

      return description
    } else {
      if (rewardDrop) {
        return getRewardsDescription()
      } else {
        return description
      }
    }
  }

  const getRewardsDescription = () => {
    if (isOnOrBeforeNow(time_launch)) {
      return `Introducing our very first Fan Rewards drop! Use your Funko Fan Rewards points to buy Digital Pop! packs on Funko.com. Collect by ${formattedPacificDate(
        redeem_start_date,
        DateFormatter.LongHours,
        true
      )}`
    } else if (isOnOrBeforeNow(time_launch)) {
      return 'Introducing our very first Fan Rewards drop! Use your Funko Fan Rewards points to buy Digital Pop! packs on Funko.com. Packs are available now!'
    } else {
      return drop.mainCopy
    }
  }

  return (
    <p className={classNames(baseClassName, className)}>
      <span
        className="inline"
        dangerouslySetInnerHTML={{ __html: getBladeDescription() }}
      />{' '}
      {!isDetailPage && <DropAvailDate />}
    </p>
  )
}

export default DropHeroDescription
